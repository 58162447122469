.footer {
  position: absolute;
  bottom: 0px;
  padding: 20px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100vw;
  box-sizing: border-box;
}

@media screen and (max-width: 630px) {
  .footer {
    display: block;
  }
}

@media screen and (max-width: 480px) {
  .footer {
    padding: 50px 30px;
  }
}

@media screen and (max-width: 768px) {
  .footer {
    position: unset;
    margin-top: 2.5rem;
  }
}
