.hightlightLink {
  position: relative;
  display: inline-block;
  color: #67e4e5 !important;
  text-decoration: none;
  font-size: 5px;
  margin: 0 15px;
  outline: 0;
}

@media screen and (max-width: 1024px) {
  .hightlightLink {
    margin: 0 10px;
  }
}

.hightlightLink:after {
  position: absolute;
  top: 120%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #67e4e5;
  content: "";
  opacity: 0;
  -webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  transition: height 0.3s, opacity 0.3s, transform 0.3s;
  transition: height 0.3s, opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  box-sizing: border-box;
}

@media screen and (max-width: 850px) {
  .hightlightLink:after {
    display: none;
  }
}

.hightlightLink:focus:after,
.hightlightLink:hover:after {
  height: 1px;
  border-radius: 3px;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.text {
  font-size: 0.8rem;
  display: inline;
  letter-spacing: 1px;
}

@media screen and (max-width: 1000px) {
  .text {
    display: none;
  }
}

img {
  display: none;
  width: 22px;
}

@media screen and (max-width: 1000px) {
  img {
    display: inline-block;
  }
}
