.footer__copyright {
  font-size: 0.8rem;
  width: 250px;
}

@media screen and (max-width: 630px) {
  .footer__copyright {
    width: auto;
    text-align: center;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 630px) {
  .bottom {
    display: inline-block;
  }
}

@media screen and (max-width: 480px) {
  .bottom {
    display: block;
  }
}
