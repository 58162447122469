.button {
  display: inline-block;
  margin: 3px 3px 3px 0;
  font-family: Inconsolata, monospace;
  color: #161822;
  font-weight: 700;
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  background-color: #89b9c2 !important;
  background: 0 0;
  transition: all 0.1s ease-out;
}

.button:hover {
  background-color: #96c5cf !important;
  cursor: pointer;
}

.button_text {
  font-size: 0.8rem;
}

.button_icon {
  font-size: 0.8rem;
  margin-right: 0.5em;
}
