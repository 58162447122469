.containerIron {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
}
.ironman {
  animation: jet 1s infinite alternate, move 1s infinite alternate;
}

@-moz-keyframes jet {
  from {
    width: 10px;
    height: 10px;
    background-color: #000;
    box-shadow: -30px 0 #000, -20px 0 #000, -10px 0 #000, 10px 0 #000,
      20px 0 #000, 30px 0 #000, 40px 0 #000, -40px 10px #000, -30px 10px #f00,
      -20px 10px #f00, -10px 10px #f00, 0px 10px #f00, 10px 10px #f00,
      20px 10px #f00, 30px 10px #f00, 40px 10px #f00, 50px 10px #000,
      -50px 20px #000, -40px 20px #f00, -30px 20px #eef30c, -20px 20px #eef30c,
      -10px 20px #f00, 0px 20px #f00, 10px 20px #f00, 20px 20px #eef30c,
      30px 20px #eef30c, 40px 20px #f00, 50px 20px #f00, 60px 20px #000,
      -50px 30px #000, -40px 30px #eef30c, -30px 30px #eef30c,
      -20px 30px #eef30c, -10px 30px #f00, 0px 30px #f00, 10px 30px #f00,
      20px 30px #eef30c, 30px 30px #eef30c, 40px 30px #eef30c, 50px 30px #f00,
      60px 30px #000, -50px 40px #000, -40px 40px #eef30c, -30px 40px #fff,
      -20px 40px #fff, -10px 40px #f00, 0px 40px #f00, 10px 40px #f00,
      20px 40px #fff, 30px 40px #fff, 40px 40px #eef30c, 50px 40px #f00,
      60px 40px #000, -50px 50px #000, -40px 50px #eef30c, -30px 50px #eef30c,
      -20px 50px #eef30c, -10px 50px #eef30c, 0px 50px #eef30c,
      10px 50px #eef30c, 20px 50px #eef30c, 30px 50px #eef30c, 40px 50px #eef30c,
      50px 50px #f00, 60px 50px #000, -50px 60px #000, -40px 60px #f00,
      -30px 60px #eef30c, -20px 60px #eef30c, -10px 60px #eef30c,
      0px 60px #eef30c, 10px 60px #eef30c, 20px 60px #eef30c, 30px 60px #eef30c,
      40px 60px #f00, 50px 60px #f00, 60px 60px #000, -50px 70px #000,
      -40px 70px #f00, -30px 70px #eef30c, -20px 70px #eef30c, -10px 70px #000,
      0px 70px #000, 10px 70px #000, 20px 70px #eef30c, 30px 70px #eef30c,
      40px 70px #f00, 50px 70px #f00, 60px 70px #000, -40px 80px #000,
      -30px 80px #f00, -20px 80px #eef30c, -10px 80px #eef30c, 0px 80px #eef30c,
      10px 80px #eef30c, 20px 80px #eef30c, 30px 80px #eef30c, 40px 80px #f00,
      50px 80px #000, -40px 80px #000, -30px 90px #000, -20px 90px #000,
      -10px 90px #000, 0px 90px #000, 10px 90px #000, 20px 90px #000,
      30px 90px #000, 40px 90px #000, -40px 100px #000, -30px 100px #f00,
      -20px 100px #f00, -10px 100px #f00, 0px 100px #f00, 10px 100px #f00,
      20px 100px #f00, 30px 100px #f00, 40px 100px #f00, 50px 100px #000,
      -50px 110px #000, -40px 110px #eef30c, -30px 110px #000, -20px 110px #f00,
      -10px 110px #87c0ea, 0px 110px #87c0ea, 10px 110px #87c0ea,
      20px 110px #f00, 30px 110px #f00, 40px 110px #000, 50px 110px #eef30c,
      60px 110px #000, -50px 120px #000, -40px 120px #eef30c, -30px 120px #000,
      -20px 120px #f00, -10px 120px #f00, 0px 120px #87c0ea, 10px 120px #f00,
      20px 120px #f00, 30px 120px #f00, 40px 120px #000, 50px 120px #eef30c,
      60px 120px #000, -60px 130px #000, -50px 130px #f00, -40px 130px #000,
      -30px 130px #000, -20px 130px #f00, -10px 130px #f00, 0px 130px #f00,
      10px 130px #f00, 20px 130px #f00, 30px 130px #f00, 40px 130px #000,
      50px 130px #000, 60px 130px #f00, 70px 130px #000, -60px 140px #000,
      -50px 140px #000, -30px 140px #000, -20px 140px #eef30c, -10px 140px #f00,
      0px 140px #f00, 10px 140px #f00, 20px 140px #eef30c, 30px 140px #eef30c,
      40px 140px #000, 60px 140px #000, 70px 140px #000, -30px 150px #000,
      -20px 150px #000, -10px 150px #f00, 0px 150px #000, 10px 150px #000,
      20px 150px #f00, 30px 150px #f00, 40px 150px #000, -30px 160px #000,
      -20px 160px #000, -10px 160px #000, 20px 160px #000, 30px 160px #000,
      40px 160px #000, -30px 170px #18212d, -20px 170px #18212d,
      -10px 170px #18212d, 20px 170px #18212d, 30px 170px #18212d,
      40px 170px #18212d, -30px 180px #18212d, -20px 180px #18212d,
      -10px 180px #18212d, 20px 180px #18212d, 30px 180px #18212d,
      40px 180px #18212d, -20px 190px #18212d, -10px 190px #18212d,
      30px 190px #18212d, 40px 190px #18212d;
  }
  to {
    width: 10px;
    height: 10px;
    background-color: #000;
    box-shadow: -30px 0 #000, -20px 0 #000, -10px 0 #000, 10px 0 #000,
      20px 0 #000, 30px 0 #000, 40px 0 #000, -40px 10px #000, -30px 10px #f00,
      -20px 10px #f00, -10px 10px #f00, 0px 10px #f00, 10px 10px #f00,
      20px 10px #f00, 30px 10px #f00, 40px 10px #f00, 50px 10px #000,
      -50px 20px #000, -40px 20px #f00, -30px 20px #eef30c, -20px 20px #eef30c,
      -10px 20px #f00, 0px 20px #f00, 10px 20px #f00, 20px 20px #eef30c,
      30px 20px #eef30c, 40px 20px #f00, 50px 20px #f00, 60px 20px #000,
      -50px 30px #000, -40px 30px #eef30c, -30px 30px #eef30c,
      -20px 30px #eef30c, -10px 30px #f00, 0px 30px #f00, 10px 30px #f00,
      20px 30px #eef30c, 30px 30px #eef30c, 40px 30px #eef30c, 50px 30px #f00,
      60px 30px #000, -50px 40px #000, -40px 40px #eef30c, -30px 40px #fff,
      -20px 40px #fff, -10px 40px #f00, 0px 40px #f00, 10px 40px #f00,
      20px 40px #fff, 30px 40px #fff, 40px 40px #eef30c, 50px 40px #f00,
      60px 40px #000, -50px 50px #000, -40px 50px #eef30c, -30px 50px #eef30c,
      -20px 50px #eef30c, -10px 50px #eef30c, 0px 50px #eef30c,
      10px 50px #eef30c, 20px 50px #eef30c, 30px 50px #eef30c, 40px 50px #eef30c,
      50px 50px #f00, 60px 50px #000, -50px 60px #000, -40px 60px #f00,
      -30px 60px #eef30c, -20px 60px #eef30c, -10px 60px #eef30c,
      0px 60px #eef30c, 10px 60px #eef30c, 20px 60px #eef30c, 30px 60px #eef30c,
      40px 60px #f00, 50px 60px #f00, 60px 60px #000, -50px 70px #000,
      -40px 70px #f00, -30px 70px #eef30c, -20px 70px #eef30c, -10px 70px #000,
      0px 70px #000, 10px 70px #000, 20px 70px #eef30c, 30px 70px #eef30c,
      40px 70px #f00, 50px 70px #f00, 60px 70px #000, -40px 80px #000,
      -30px 80px #f00, -20px 80px #eef30c, -10px 80px #eef30c, 0px 80px #eef30c,
      10px 80px #eef30c, 20px 80px #eef30c, 30px 80px #eef30c, 40px 80px #f00,
      50px 80px #000, -40px 80px #000, -30px 90px #000, -20px 90px #000,
      -10px 90px #000, 0px 90px #000, 10px 90px #000, 20px 90px #000,
      30px 90px #000, 40px 90px #000, -40px 100px #000, -30px 100px #f00,
      -20px 100px #f00, -10px 100px #f00, 0px 100px #f00, 10px 100px #f00,
      20px 100px #f00, 30px 100px #f00, 40px 100px #f00, 50px 100px #000,
      -50px 110px #000, -40px 110px #eef30c, -30px 110px #000, -20px 110px #f00,
      -10px 110px #87c0ea, 0px 110px #87c0ea, 10px 110px #87c0ea,
      20px 110px #f00, 30px 110px #f00, 40px 110px #000, 50px 110px #eef30c,
      60px 110px #000, -50px 120px #000, -40px 120px #eef30c, -30px 120px #000,
      -20px 120px #f00, -10px 120px #f00, 0px 120px #87c0ea, 10px 120px #f00,
      20px 120px #f00, 30px 120px #f00, 40px 120px #000, 50px 120px #eef30c,
      60px 120px #000, -60px 130px #000, -50px 130px #f00, -40px 130px #000,
      -30px 130px #000, -20px 130px #f00, -10px 130px #f00, 0px 130px #f00,
      10px 130px #f00, 20px 130px #f00, 30px 130px #f00, 40px 130px #000,
      50px 130px #000, 60px 130px #f00, 70px 130px #000, -60px 140px #000,
      -50px 140px #000, -30px 140px #000, -20px 140px #eef30c, -10px 140px #f00,
      0px 140px #f00, 10px 140px #f00, 20px 140px #eef30c, 30px 140px #eef30c,
      40px 140px #000, 60px 140px #000, 70px 140px #000, -30px 150px #000,
      -20px 150px #000, -10px 150px #f00, 0px 150px #000, 10px 150px #000,
      20px 150px #f00, 30px 150px #f00, 40px 150px #000, -30px 160px #000,
      -20px 160px #000, -10px 160px #000, 20px 160px #000, 30px 160px #000,
      40px 160px #000, -30px 170px #87c0ea, -20px 170px #ddd,
      -10px 170px #87c0ea, 20px 170px #87c0ea, 30px 170px #ddd,
      40px 170px #87c0ea, -30px 180px #87c0ea, -20px 180px #ddd,
      -10px 180px #87c0ea, 20px 180px #87c0ea, 30px 180px #ddd,
      40px 180px #87c0ea, -20px 190px #87c0ea, -10px 190px #87c0ea,
      30px 190px #87c0ea, 40px 190px #87c0ea;
  }
}
@-webkit-keyframes jet {
  from {
    width: 10px;
    height: 10px;
    background-color: #000;
    box-shadow: -30px 0 #000, -20px 0 #000, -10px 0 #000, 10px 0 #000,
      20px 0 #000, 30px 0 #000, 40px 0 #000, -40px 10px #000, -30px 10px #f00,
      -20px 10px #f00, -10px 10px #f00, 0px 10px #f00, 10px 10px #f00,
      20px 10px #f00, 30px 10px #f00, 40px 10px #f00, 50px 10px #000,
      -50px 20px #000, -40px 20px #f00, -30px 20px #eef30c, -20px 20px #eef30c,
      -10px 20px #f00, 0px 20px #f00, 10px 20px #f00, 20px 20px #eef30c,
      30px 20px #eef30c, 40px 20px #f00, 50px 20px #f00, 60px 20px #000,
      -50px 30px #000, -40px 30px #eef30c, -30px 30px #eef30c,
      -20px 30px #eef30c, -10px 30px #f00, 0px 30px #f00, 10px 30px #f00,
      20px 30px #eef30c, 30px 30px #eef30c, 40px 30px #eef30c, 50px 30px #f00,
      60px 30px #000, -50px 40px #000, -40px 40px #eef30c, -30px 40px #fff,
      -20px 40px #fff, -10px 40px #f00, 0px 40px #f00, 10px 40px #f00,
      20px 40px #fff, 30px 40px #fff, 40px 40px #eef30c, 50px 40px #f00,
      60px 40px #000, -50px 50px #000, -40px 50px #eef30c, -30px 50px #eef30c,
      -20px 50px #eef30c, -10px 50px #eef30c, 0px 50px #eef30c,
      10px 50px #eef30c, 20px 50px #eef30c, 30px 50px #eef30c, 40px 50px #eef30c,
      50px 50px #f00, 60px 50px #000, -50px 60px #000, -40px 60px #f00,
      -30px 60px #eef30c, -20px 60px #eef30c, -10px 60px #eef30c,
      0px 60px #eef30c, 10px 60px #eef30c, 20px 60px #eef30c, 30px 60px #eef30c,
      40px 60px #f00, 50px 60px #f00, 60px 60px #000, -50px 70px #000,
      -40px 70px #f00, -30px 70px #eef30c, -20px 70px #eef30c, -10px 70px #000,
      0px 70px #000, 10px 70px #000, 20px 70px #eef30c, 30px 70px #eef30c,
      40px 70px #f00, 50px 70px #f00, 60px 70px #000, -40px 80px #000,
      -30px 80px #f00, -20px 80px #eef30c, -10px 80px #eef30c, 0px 80px #eef30c,
      10px 80px #eef30c, 20px 80px #eef30c, 30px 80px #eef30c, 40px 80px #f00,
      50px 80px #000, -40px 80px #000, -30px 90px #000, -20px 90px #000,
      -10px 90px #000, 0px 90px #000, 10px 90px #000, 20px 90px #000,
      30px 90px #000, 40px 90px #000, -40px 100px #000, -30px 100px #f00,
      -20px 100px #f00, -10px 100px #f00, 0px 100px #f00, 10px 100px #f00,
      20px 100px #f00, 30px 100px #f00, 40px 100px #f00, 50px 100px #000,
      -50px 110px #000, -40px 110px #eef30c, -30px 110px #000, -20px 110px #f00,
      -10px 110px #87c0ea, 0px 110px #87c0ea, 10px 110px #87c0ea,
      20px 110px #f00, 30px 110px #f00, 40px 110px #000, 50px 110px #eef30c,
      60px 110px #000, -50px 120px #000, -40px 120px #eef30c, -30px 120px #000,
      -20px 120px #f00, -10px 120px #f00, 0px 120px #87c0ea, 10px 120px #f00,
      20px 120px #f00, 30px 120px #f00, 40px 120px #000, 50px 120px #eef30c,
      60px 120px #000, -60px 130px #000, -50px 130px #f00, -40px 130px #000,
      -30px 130px #000, -20px 130px #f00, -10px 130px #f00, 0px 130px #f00,
      10px 130px #f00, 20px 130px #f00, 30px 130px #f00, 40px 130px #000,
      50px 130px #000, 60px 130px #f00, 70px 130px #000, -60px 140px #000,
      -50px 140px #000, -30px 140px #000, -20px 140px #eef30c, -10px 140px #f00,
      0px 140px #f00, 10px 140px #f00, 20px 140px #eef30c, 30px 140px #eef30c,
      40px 140px #000, 60px 140px #000, 70px 140px #000, -30px 150px #000,
      -20px 150px #000, -10px 150px #f00, 0px 150px #000, 10px 150px #000,
      20px 150px #f00, 30px 150px #f00, 40px 150px #000, -30px 160px #000,
      -20px 160px #000, -10px 160px #000, 20px 160px #000, 30px 160px #000,
      40px 160px #000, -30px 170px #18212d, -20px 170px #18212d,
      -10px 170px #18212d, 20px 170px #18212d, 30px 170px #18212d,
      40px 170px #18212d, -30px 180px #18212d, -20px 180px #18212d,
      -10px 180px #18212d, 20px 180px #18212d, 30px 180px #18212d,
      40px 180px #18212d, -20px 190px #18212d, -10px 190px #18212d,
      30px 190px #18212d, 40px 190px #18212d;
  }
  to {
    width: 10px;
    height: 10px;
    background-color: #000;
    box-shadow: -30px 0 #000, -20px 0 #000, -10px 0 #000, 10px 0 #000,
      20px 0 #000, 30px 0 #000, 40px 0 #000, -40px 10px #000, -30px 10px #f00,
      -20px 10px #f00, -10px 10px #f00, 0px 10px #f00, 10px 10px #f00,
      20px 10px #f00, 30px 10px #f00, 40px 10px #f00, 50px 10px #000,
      -50px 20px #000, -40px 20px #f00, -30px 20px #eef30c, -20px 20px #eef30c,
      -10px 20px #f00, 0px 20px #f00, 10px 20px #f00, 20px 20px #eef30c,
      30px 20px #eef30c, 40px 20px #f00, 50px 20px #f00, 60px 20px #000,
      -50px 30px #000, -40px 30px #eef30c, -30px 30px #eef30c,
      -20px 30px #eef30c, -10px 30px #f00, 0px 30px #f00, 10px 30px #f00,
      20px 30px #eef30c, 30px 30px #eef30c, 40px 30px #eef30c, 50px 30px #f00,
      60px 30px #000, -50px 40px #000, -40px 40px #eef30c, -30px 40px #fff,
      -20px 40px #fff, -10px 40px #f00, 0px 40px #f00, 10px 40px #f00,
      20px 40px #fff, 30px 40px #fff, 40px 40px #eef30c, 50px 40px #f00,
      60px 40px #000, -50px 50px #000, -40px 50px #eef30c, -30px 50px #eef30c,
      -20px 50px #eef30c, -10px 50px #eef30c, 0px 50px #eef30c,
      10px 50px #eef30c, 20px 50px #eef30c, 30px 50px #eef30c, 40px 50px #eef30c,
      50px 50px #f00, 60px 50px #000, -50px 60px #000, -40px 60px #f00,
      -30px 60px #eef30c, -20px 60px #eef30c, -10px 60px #eef30c,
      0px 60px #eef30c, 10px 60px #eef30c, 20px 60px #eef30c, 30px 60px #eef30c,
      40px 60px #f00, 50px 60px #f00, 60px 60px #000, -50px 70px #000,
      -40px 70px #f00, -30px 70px #eef30c, -20px 70px #eef30c, -10px 70px #000,
      0px 70px #000, 10px 70px #000, 20px 70px #eef30c, 30px 70px #eef30c,
      40px 70px #f00, 50px 70px #f00, 60px 70px #000, -40px 80px #000,
      -30px 80px #f00, -20px 80px #eef30c, -10px 80px #eef30c, 0px 80px #eef30c,
      10px 80px #eef30c, 20px 80px #eef30c, 30px 80px #eef30c, 40px 80px #f00,
      50px 80px #000, -40px 80px #000, -30px 90px #000, -20px 90px #000,
      -10px 90px #000, 0px 90px #000, 10px 90px #000, 20px 90px #000,
      30px 90px #000, 40px 90px #000, -40px 100px #000, -30px 100px #f00,
      -20px 100px #f00, -10px 100px #f00, 0px 100px #f00, 10px 100px #f00,
      20px 100px #f00, 30px 100px #f00, 40px 100px #f00, 50px 100px #000,
      -50px 110px #000, -40px 110px #eef30c, -30px 110px #000, -20px 110px #f00,
      -10px 110px #87c0ea, 0px 110px #87c0ea, 10px 110px #87c0ea,
      20px 110px #f00, 30px 110px #f00, 40px 110px #000, 50px 110px #eef30c,
      60px 110px #000, -50px 120px #000, -40px 120px #eef30c, -30px 120px #000,
      -20px 120px #f00, -10px 120px #f00, 0px 120px #87c0ea, 10px 120px #f00,
      20px 120px #f00, 30px 120px #f00, 40px 120px #000, 50px 120px #eef30c,
      60px 120px #000, -60px 130px #000, -50px 130px #f00, -40px 130px #000,
      -30px 130px #000, -20px 130px #f00, -10px 130px #f00, 0px 130px #f00,
      10px 130px #f00, 20px 130px #f00, 30px 130px #f00, 40px 130px #000,
      50px 130px #000, 60px 130px #f00, 70px 130px #000, -60px 140px #000,
      -50px 140px #000, -30px 140px #000, -20px 140px #eef30c, -10px 140px #f00,
      0px 140px #f00, 10px 140px #f00, 20px 140px #eef30c, 30px 140px #eef30c,
      40px 140px #000, 60px 140px #000, 70px 140px #000, -30px 150px #000,
      -20px 150px #000, -10px 150px #f00, 0px 150px #000, 10px 150px #000,
      20px 150px #f00, 30px 150px #f00, 40px 150px #000, -30px 160px #000,
      -20px 160px #000, -10px 160px #000, 20px 160px #000, 30px 160px #000,
      40px 160px #000, -30px 170px #87c0ea, -20px 170px #ddd,
      -10px 170px #87c0ea, 20px 170px #87c0ea, 30px 170px #ddd,
      40px 170px #87c0ea, -30px 180px #87c0ea, -20px 180px #ddd,
      -10px 180px #87c0ea, 20px 180px #87c0ea, 30px 180px #ddd,
      40px 180px #87c0ea, -20px 190px #87c0ea, -10px 190px #87c0ea,
      30px 190px #87c0ea, 40px 190px #87c0ea;
  }
}
@-o-keyframes jet {
  from {
    width: 10px;
    height: 10px;
    background-color: #000;
    box-shadow: -30px 0 #000, -20px 0 #000, -10px 0 #000, 10px 0 #000,
      20px 0 #000, 30px 0 #000, 40px 0 #000, -40px 10px #000, -30px 10px #f00,
      -20px 10px #f00, -10px 10px #f00, 0px 10px #f00, 10px 10px #f00,
      20px 10px #f00, 30px 10px #f00, 40px 10px #f00, 50px 10px #000,
      -50px 20px #000, -40px 20px #f00, -30px 20px #eef30c, -20px 20px #eef30c,
      -10px 20px #f00, 0px 20px #f00, 10px 20px #f00, 20px 20px #eef30c,
      30px 20px #eef30c, 40px 20px #f00, 50px 20px #f00, 60px 20px #000,
      -50px 30px #000, -40px 30px #eef30c, -30px 30px #eef30c,
      -20px 30px #eef30c, -10px 30px #f00, 0px 30px #f00, 10px 30px #f00,
      20px 30px #eef30c, 30px 30px #eef30c, 40px 30px #eef30c, 50px 30px #f00,
      60px 30px #000, -50px 40px #000, -40px 40px #eef30c, -30px 40px #fff,
      -20px 40px #fff, -10px 40px #f00, 0px 40px #f00, 10px 40px #f00,
      20px 40px #fff, 30px 40px #fff, 40px 40px #eef30c, 50px 40px #f00,
      60px 40px #000, -50px 50px #000, -40px 50px #eef30c, -30px 50px #eef30c,
      -20px 50px #eef30c, -10px 50px #eef30c, 0px 50px #eef30c,
      10px 50px #eef30c, 20px 50px #eef30c, 30px 50px #eef30c, 40px 50px #eef30c,
      50px 50px #f00, 60px 50px #000, -50px 60px #000, -40px 60px #f00,
      -30px 60px #eef30c, -20px 60px #eef30c, -10px 60px #eef30c,
      0px 60px #eef30c, 10px 60px #eef30c, 20px 60px #eef30c, 30px 60px #eef30c,
      40px 60px #f00, 50px 60px #f00, 60px 60px #000, -50px 70px #000,
      -40px 70px #f00, -30px 70px #eef30c, -20px 70px #eef30c, -10px 70px #000,
      0px 70px #000, 10px 70px #000, 20px 70px #eef30c, 30px 70px #eef30c,
      40px 70px #f00, 50px 70px #f00, 60px 70px #000, -40px 80px #000,
      -30px 80px #f00, -20px 80px #eef30c, -10px 80px #eef30c, 0px 80px #eef30c,
      10px 80px #eef30c, 20px 80px #eef30c, 30px 80px #eef30c, 40px 80px #f00,
      50px 80px #000, -40px 80px #000, -30px 90px #000, -20px 90px #000,
      -10px 90px #000, 0px 90px #000, 10px 90px #000, 20px 90px #000,
      30px 90px #000, 40px 90px #000, -40px 100px #000, -30px 100px #f00,
      -20px 100px #f00, -10px 100px #f00, 0px 100px #f00, 10px 100px #f00,
      20px 100px #f00, 30px 100px #f00, 40px 100px #f00, 50px 100px #000,
      -50px 110px #000, -40px 110px #eef30c, -30px 110px #000, -20px 110px #f00,
      -10px 110px #87c0ea, 0px 110px #87c0ea, 10px 110px #87c0ea,
      20px 110px #f00, 30px 110px #f00, 40px 110px #000, 50px 110px #eef30c,
      60px 110px #000, -50px 120px #000, -40px 120px #eef30c, -30px 120px #000,
      -20px 120px #f00, -10px 120px #f00, 0px 120px #87c0ea, 10px 120px #f00,
      20px 120px #f00, 30px 120px #f00, 40px 120px #000, 50px 120px #eef30c,
      60px 120px #000, -60px 130px #000, -50px 130px #f00, -40px 130px #000,
      -30px 130px #000, -20px 130px #f00, -10px 130px #f00, 0px 130px #f00,
      10px 130px #f00, 20px 130px #f00, 30px 130px #f00, 40px 130px #000,
      50px 130px #000, 60px 130px #f00, 70px 130px #000, -60px 140px #000,
      -50px 140px #000, -30px 140px #000, -20px 140px #eef30c, -10px 140px #f00,
      0px 140px #f00, 10px 140px #f00, 20px 140px #eef30c, 30px 140px #eef30c,
      40px 140px #000, 60px 140px #000, 70px 140px #000, -30px 150px #000,
      -20px 150px #000, -10px 150px #f00, 0px 150px #000, 10px 150px #000,
      20px 150px #f00, 30px 150px #f00, 40px 150px #000, -30px 160px #000,
      -20px 160px #000, -10px 160px #000, 20px 160px #000, 30px 160px #000,
      40px 160px #000, -30px 170px #18212d, -20px 170px #18212d,
      -10px 170px #18212d, 20px 170px #18212d, 30px 170px #18212d,
      40px 170px #18212d, -30px 180px #18212d, -20px 180px #18212d,
      -10px 180px #18212d, 20px 180px #18212d, 30px 180px #18212d,
      40px 180px #18212d, -20px 190px #18212d, -10px 190px #18212d,
      30px 190px #18212d, 40px 190px #18212d;
  }
  to {
    width: 10px;
    height: 10px;
    background-color: #000;
    box-shadow: -30px 0 #000, -20px 0 #000, -10px 0 #000, 10px 0 #000,
      20px 0 #000, 30px 0 #000, 40px 0 #000, -40px 10px #000, -30px 10px #f00,
      -20px 10px #f00, -10px 10px #f00, 0px 10px #f00, 10px 10px #f00,
      20px 10px #f00, 30px 10px #f00, 40px 10px #f00, 50px 10px #000,
      -50px 20px #000, -40px 20px #f00, -30px 20px #eef30c, -20px 20px #eef30c,
      -10px 20px #f00, 0px 20px #f00, 10px 20px #f00, 20px 20px #eef30c,
      30px 20px #eef30c, 40px 20px #f00, 50px 20px #f00, 60px 20px #000,
      -50px 30px #000, -40px 30px #eef30c, -30px 30px #eef30c,
      -20px 30px #eef30c, -10px 30px #f00, 0px 30px #f00, 10px 30px #f00,
      20px 30px #eef30c, 30px 30px #eef30c, 40px 30px #eef30c, 50px 30px #f00,
      60px 30px #000, -50px 40px #000, -40px 40px #eef30c, -30px 40px #fff,
      -20px 40px #fff, -10px 40px #f00, 0px 40px #f00, 10px 40px #f00,
      20px 40px #fff, 30px 40px #fff, 40px 40px #eef30c, 50px 40px #f00,
      60px 40px #000, -50px 50px #000, -40px 50px #eef30c, -30px 50px #eef30c,
      -20px 50px #eef30c, -10px 50px #eef30c, 0px 50px #eef30c,
      10px 50px #eef30c, 20px 50px #eef30c, 30px 50px #eef30c, 40px 50px #eef30c,
      50px 50px #f00, 60px 50px #000, -50px 60px #000, -40px 60px #f00,
      -30px 60px #eef30c, -20px 60px #eef30c, -10px 60px #eef30c,
      0px 60px #eef30c, 10px 60px #eef30c, 20px 60px #eef30c, 30px 60px #eef30c,
      40px 60px #f00, 50px 60px #f00, 60px 60px #000, -50px 70px #000,
      -40px 70px #f00, -30px 70px #eef30c, -20px 70px #eef30c, -10px 70px #000,
      0px 70px #000, 10px 70px #000, 20px 70px #eef30c, 30px 70px #eef30c,
      40px 70px #f00, 50px 70px #f00, 60px 70px #000, -40px 80px #000,
      -30px 80px #f00, -20px 80px #eef30c, -10px 80px #eef30c, 0px 80px #eef30c,
      10px 80px #eef30c, 20px 80px #eef30c, 30px 80px #eef30c, 40px 80px #f00,
      50px 80px #000, -40px 80px #000, -30px 90px #000, -20px 90px #000,
      -10px 90px #000, 0px 90px #000, 10px 90px #000, 20px 90px #000,
      30px 90px #000, 40px 90px #000, -40px 100px #000, -30px 100px #f00,
      -20px 100px #f00, -10px 100px #f00, 0px 100px #f00, 10px 100px #f00,
      20px 100px #f00, 30px 100px #f00, 40px 100px #f00, 50px 100px #000,
      -50px 110px #000, -40px 110px #eef30c, -30px 110px #000, -20px 110px #f00,
      -10px 110px #87c0ea, 0px 110px #87c0ea, 10px 110px #87c0ea,
      20px 110px #f00, 30px 110px #f00, 40px 110px #000, 50px 110px #eef30c,
      60px 110px #000, -50px 120px #000, -40px 120px #eef30c, -30px 120px #000,
      -20px 120px #f00, -10px 120px #f00, 0px 120px #87c0ea, 10px 120px #f00,
      20px 120px #f00, 30px 120px #f00, 40px 120px #000, 50px 120px #eef30c,
      60px 120px #000, -60px 130px #000, -50px 130px #f00, -40px 130px #000,
      -30px 130px #000, -20px 130px #f00, -10px 130px #f00, 0px 130px #f00,
      10px 130px #f00, 20px 130px #f00, 30px 130px #f00, 40px 130px #000,
      50px 130px #000, 60px 130px #f00, 70px 130px #000, -60px 140px #000,
      -50px 140px #000, -30px 140px #000, -20px 140px #eef30c, -10px 140px #f00,
      0px 140px #f00, 10px 140px #f00, 20px 140px #eef30c, 30px 140px #eef30c,
      40px 140px #000, 60px 140px #000, 70px 140px #000, -30px 150px #000,
      -20px 150px #000, -10px 150px #f00, 0px 150px #000, 10px 150px #000,
      20px 150px #f00, 30px 150px #f00, 40px 150px #000, -30px 160px #000,
      -20px 160px #000, -10px 160px #000, 20px 160px #000, 30px 160px #000,
      40px 160px #000, -30px 170px #87c0ea, -20px 170px #ddd,
      -10px 170px #87c0ea, 20px 170px #87c0ea, 30px 170px #ddd,
      40px 170px #87c0ea, -30px 180px #87c0ea, -20px 180px #ddd,
      -10px 180px #87c0ea, 20px 180px #87c0ea, 30px 180px #ddd,
      40px 180px #87c0ea, -20px 190px #87c0ea, -10px 190px #87c0ea,
      30px 190px #87c0ea, 40px 190px #87c0ea;
  }
}
@keyframes jet {
  from {
    width: 10px;
    height: 10px;
    background-color: #000;
    box-shadow: -30px 0 #000, -20px 0 #000, -10px 0 #000, 10px 0 #000,
      20px 0 #000, 30px 0 #000, 40px 0 #000, -40px 10px #000, -30px 10px #f00,
      -20px 10px #f00, -10px 10px #f00, 0px 10px #f00, 10px 10px #f00,
      20px 10px #f00, 30px 10px #f00, 40px 10px #f00, 50px 10px #000,
      -50px 20px #000, -40px 20px #f00, -30px 20px #eef30c, -20px 20px #eef30c,
      -10px 20px #f00, 0px 20px #f00, 10px 20px #f00, 20px 20px #eef30c,
      30px 20px #eef30c, 40px 20px #f00, 50px 20px #f00, 60px 20px #000,
      -50px 30px #000, -40px 30px #eef30c, -30px 30px #eef30c,
      -20px 30px #eef30c, -10px 30px #f00, 0px 30px #f00, 10px 30px #f00,
      20px 30px #eef30c, 30px 30px #eef30c, 40px 30px #eef30c, 50px 30px #f00,
      60px 30px #000, -50px 40px #000, -40px 40px #eef30c, -30px 40px #fff,
      -20px 40px #fff, -10px 40px #f00, 0px 40px #f00, 10px 40px #f00,
      20px 40px #fff, 30px 40px #fff, 40px 40px #eef30c, 50px 40px #f00,
      60px 40px #000, -50px 50px #000, -40px 50px #eef30c, -30px 50px #eef30c,
      -20px 50px #eef30c, -10px 50px #eef30c, 0px 50px #eef30c,
      10px 50px #eef30c, 20px 50px #eef30c, 30px 50px #eef30c, 40px 50px #eef30c,
      50px 50px #f00, 60px 50px #000, -50px 60px #000, -40px 60px #f00,
      -30px 60px #eef30c, -20px 60px #eef30c, -10px 60px #eef30c,
      0px 60px #eef30c, 10px 60px #eef30c, 20px 60px #eef30c, 30px 60px #eef30c,
      40px 60px #f00, 50px 60px #f00, 60px 60px #000, -50px 70px #000,
      -40px 70px #f00, -30px 70px #eef30c, -20px 70px #eef30c, -10px 70px #000,
      0px 70px #000, 10px 70px #000, 20px 70px #eef30c, 30px 70px #eef30c,
      40px 70px #f00, 50px 70px #f00, 60px 70px #000, -40px 80px #000,
      -30px 80px #f00, -20px 80px #eef30c, -10px 80px #eef30c, 0px 80px #eef30c,
      10px 80px #eef30c, 20px 80px #eef30c, 30px 80px #eef30c, 40px 80px #f00,
      50px 80px #000, -40px 80px #000, -30px 90px #000, -20px 90px #000,
      -10px 90px #000, 0px 90px #000, 10px 90px #000, 20px 90px #000,
      30px 90px #000, 40px 90px #000, -40px 100px #000, -30px 100px #f00,
      -20px 100px #f00, -10px 100px #f00, 0px 100px #f00, 10px 100px #f00,
      20px 100px #f00, 30px 100px #f00, 40px 100px #f00, 50px 100px #000,
      -50px 110px #000, -40px 110px #eef30c, -30px 110px #000, -20px 110px #f00,
      -10px 110px #87c0ea, 0px 110px #87c0ea, 10px 110px #87c0ea,
      20px 110px #f00, 30px 110px #f00, 40px 110px #000, 50px 110px #eef30c,
      60px 110px #000, -50px 120px #000, -40px 120px #eef30c, -30px 120px #000,
      -20px 120px #f00, -10px 120px #f00, 0px 120px #87c0ea, 10px 120px #f00,
      20px 120px #f00, 30px 120px #f00, 40px 120px #000, 50px 120px #eef30c,
      60px 120px #000, -60px 130px #000, -50px 130px #f00, -40px 130px #000,
      -30px 130px #000, -20px 130px #f00, -10px 130px #f00, 0px 130px #f00,
      10px 130px #f00, 20px 130px #f00, 30px 130px #f00, 40px 130px #000,
      50px 130px #000, 60px 130px #f00, 70px 130px #000, -60px 140px #000,
      -50px 140px #000, -30px 140px #000, -20px 140px #eef30c, -10px 140px #f00,
      0px 140px #f00, 10px 140px #f00, 20px 140px #eef30c, 30px 140px #eef30c,
      40px 140px #000, 60px 140px #000, 70px 140px #000, -30px 150px #000,
      -20px 150px #000, -10px 150px #f00, 0px 150px #000, 10px 150px #000,
      20px 150px #f00, 30px 150px #f00, 40px 150px #000, -30px 160px #000,
      -20px 160px #000, -10px 160px #000, 20px 160px #000, 30px 160px #000,
      40px 160px #000, -30px 170px #18212d, -20px 170px #18212d,
      -10px 170px #18212d, 20px 170px #18212d, 30px 170px #18212d,
      40px 170px #18212d, -30px 180px #18212d, -20px 180px #18212d,
      -10px 180px #18212d, 20px 180px #18212d, 30px 180px #18212d,
      40px 180px #18212d, -20px 190px #18212d, -10px 190px #18212d,
      30px 190px #18212d, 40px 190px #18212d;
  }
  to {
    width: 10px;
    height: 10px;
    background-color: #000;
    box-shadow: -30px 0 #000, -20px 0 #000, -10px 0 #000, 10px 0 #000,
      20px 0 #000, 30px 0 #000, 40px 0 #000, -40px 10px #000, -30px 10px #f00,
      -20px 10px #f00, -10px 10px #f00, 0px 10px #f00, 10px 10px #f00,
      20px 10px #f00, 30px 10px #f00, 40px 10px #f00, 50px 10px #000,
      -50px 20px #000, -40px 20px #f00, -30px 20px #eef30c, -20px 20px #eef30c,
      -10px 20px #f00, 0px 20px #f00, 10px 20px #f00, 20px 20px #eef30c,
      30px 20px #eef30c, 40px 20px #f00, 50px 20px #f00, 60px 20px #000,
      -50px 30px #000, -40px 30px #eef30c, -30px 30px #eef30c,
      -20px 30px #eef30c, -10px 30px #f00, 0px 30px #f00, 10px 30px #f00,
      20px 30px #eef30c, 30px 30px #eef30c, 40px 30px #eef30c, 50px 30px #f00,
      60px 30px #000, -50px 40px #000, -40px 40px #eef30c, -30px 40px #fff,
      -20px 40px #fff, -10px 40px #f00, 0px 40px #f00, 10px 40px #f00,
      20px 40px #fff, 30px 40px #fff, 40px 40px #eef30c, 50px 40px #f00,
      60px 40px #000, -50px 50px #000, -40px 50px #eef30c, -30px 50px #eef30c,
      -20px 50px #eef30c, -10px 50px #eef30c, 0px 50px #eef30c,
      10px 50px #eef30c, 20px 50px #eef30c, 30px 50px #eef30c, 40px 50px #eef30c,
      50px 50px #f00, 60px 50px #000, -50px 60px #000, -40px 60px #f00,
      -30px 60px #eef30c, -20px 60px #eef30c, -10px 60px #eef30c,
      0px 60px #eef30c, 10px 60px #eef30c, 20px 60px #eef30c, 30px 60px #eef30c,
      40px 60px #f00, 50px 60px #f00, 60px 60px #000, -50px 70px #000,
      -40px 70px #f00, -30px 70px #eef30c, -20px 70px #eef30c, -10px 70px #000,
      0px 70px #000, 10px 70px #000, 20px 70px #eef30c, 30px 70px #eef30c,
      40px 70px #f00, 50px 70px #f00, 60px 70px #000, -40px 80px #000,
      -30px 80px #f00, -20px 80px #eef30c, -10px 80px #eef30c, 0px 80px #eef30c,
      10px 80px #eef30c, 20px 80px #eef30c, 30px 80px #eef30c, 40px 80px #f00,
      50px 80px #000, -40px 80px #000, -30px 90px #000, -20px 90px #000,
      -10px 90px #000, 0px 90px #000, 10px 90px #000, 20px 90px #000,
      30px 90px #000, 40px 90px #000, -40px 100px #000, -30px 100px #f00,
      -20px 100px #f00, -10px 100px #f00, 0px 100px #f00, 10px 100px #f00,
      20px 100px #f00, 30px 100px #f00, 40px 100px #f00, 50px 100px #000,
      -50px 110px #000, -40px 110px #eef30c, -30px 110px #000, -20px 110px #f00,
      -10px 110px #87c0ea, 0px 110px #87c0ea, 10px 110px #87c0ea,
      20px 110px #f00, 30px 110px #f00, 40px 110px #000, 50px 110px #eef30c,
      60px 110px #000, -50px 120px #000, -40px 120px #eef30c, -30px 120px #000,
      -20px 120px #f00, -10px 120px #f00, 0px 120px #87c0ea, 10px 120px #f00,
      20px 120px #f00, 30px 120px #f00, 40px 120px #000, 50px 120px #eef30c,
      60px 120px #000, -60px 130px #000, -50px 130px #f00, -40px 130px #000,
      -30px 130px #000, -20px 130px #f00, -10px 130px #f00, 0px 130px #f00,
      10px 130px #f00, 20px 130px #f00, 30px 130px #f00, 40px 130px #000,
      50px 130px #000, 60px 130px #f00, 70px 130px #000, -60px 140px #000,
      -50px 140px #000, -30px 140px #000, -20px 140px #eef30c, -10px 140px #f00,
      0px 140px #f00, 10px 140px #f00, 20px 140px #eef30c, 30px 140px #eef30c,
      40px 140px #000, 60px 140px #000, 70px 140px #000, -30px 150px #000,
      -20px 150px #000, -10px 150px #f00, 0px 150px #000, 10px 150px #000,
      20px 150px #f00, 30px 150px #f00, 40px 150px #000, -30px 160px #000,
      -20px 160px #000, -10px 160px #000, 20px 160px #000, 30px 160px #000,
      40px 160px #000, -30px 170px #87c0ea, -20px 170px #ddd,
      -10px 170px #87c0ea, 20px 170px #87c0ea, 30px 170px #ddd,
      40px 170px #87c0ea, -30px 180px #87c0ea, -20px 180px #ddd,
      -10px 180px #87c0ea, 20px 180px #87c0ea, 30px 180px #ddd,
      40px 180px #87c0ea, -20px 190px #87c0ea, -10px 190px #87c0ea,
      30px 190px #87c0ea, 40px 190px #87c0ea;
  }
}

@-moz-keyframes move {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-10px, -10px);
  }
}
@-webkit-keyframes move {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-10px, -10px);
  }
}
@-o-keyframes move {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-10px, -10px);
  }
}
@keyframes move {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-10px, -10px);
  }
}
@-moz-keyframes stars {
  from {
    transform: translateY(-400px);
  }
  to {
    transform: translateY(400px);
  }
}
