.fa-envelope,
.fa-twitter,
.fa-instagram,
.fa-linkedin,
.fa-github,
.fa-behance,
.fa-dribbble,
.fa-gitlab,
.fa-folder-o,
.bottom-right-corner a {
  font-size: 1rem;
  padding: 10px 12px;
  padding-left: 0px;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #67e4e5;
}

.project-icon {
  font-size: 1.05em;
}

.button_icon {
  font-size: 0.8rem;
  margin-right: 0.5em;
}

.fa-check {
  margin-right: 0.6rem;
}

.fa-instagram:hover {
  color: #e4405f;
  cursor: pointer;
}

.fa-twitter:hover {
  color: #55acee;
  cursor: pointer;
}

.fa-linkedin:hover {
  color: #0077b5;
  cursor: pointer;
}

.fa-envelope:hover {
  color: #dd4b39;
  cursor: pointer;
}

.fa-behance:hover {
  color: #053eff;
  cursor: pointer;
}

.fa-dribbble:hover {
  color: #ea4c89;
  cursor: pointer;
}

.fa-github:hover {
  color: #6e5494;
  cursor: pointer;
}

.fa-gitlab:hover {
  color: #e2432a;
  cursor: pointer;
}
