.site {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #161822;
  color: #67e4e5;
  position: relative;
}
